body {
  background: #F1F3F4;
}

a {
  color: #6D7993;
}
a:active, a:hover, a:visited {
  color: #6D7993;
}
a.active {
  color: #576176;
}

.center {
  text-align: center;
}

hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  margin: 2em auto;
}

.main-navigation {
  background-color: #F1F3F4;
}

.footer-navigation {
  background-color: #F1F3F4;
}

@media (min-width: 600px) {
  html {
    font-size: 1.4em;
  }
}

body {
  background-color: white;
  font-weight: 400;
  line-height: 1.6;
  color: #333;
}

p {
  margin-bottom: 1.3em;
  font-size: 1.3em;
}
@media (min-width: 600px) {
  p {
    font-size: 1em;
  }
}

h1, h2, h3, h4 {
  margin: 1.414em 0 0.5em;
  font-weight: inherit;
  line-height: 1.2;
}

h1 {
  margin-top: 0;
  font-size: 3.157em;
}

h2 {
  font-size: 2.369em;
}

h3 {
  font-size: 1.777em;
}

h4 {
  font-size: 1.333em;
}

small, .font_small {
  font-size: 0.75em;
}

main {
  padding: 2em 0;
}

.landing-page > h2, .landing-page h3 {
  margin: 2em auto;
}

.welcome-modal {
  background-image: url(../images/ambiance/corpsdeballet.jpg) !important;
  background-size: cover;
}

.welcome-modal.active {
  height: 100vh;
  z-index: 100000000;
  top: 0;
}

.btn {
  display: block;
  margin: 0 auto;
}

.navbar .navbar-section:first-child {
  flex: 5;
}

.main-navigation {
  padding: 1em 0;
  position: fixed;
  width: 100%;
  z-index: 3000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.nav-icon {
  display: block;
  width: 30px;
  height: 100%;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 9999999;
}
.nav-icon span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  background: #6D7993;
}
.nav-icon span:nth-child(1) {
  top: 0px;
}
.nav-icon span:nth-child(2) {
  top: 9px;
}
.nav-icon span:nth-child(3) {
  top: 18px;
}
.nav-icon.open span:nth-child(1) {
  top: 9px;
  transform: rotate(135deg);
}
.nav-icon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.nav-icon.open span:nth-child(3) {
  top: 9px;
  transform: rotate(-135deg);
}

.modal-container {
  display: flex;
  justify-content: center;
  animation: slide-up 0.52s ease 1;
}
.modal-container .modal-container__links {
  display: flex;
  flex-direction: column;
}
.modal-container .modal-container__links .modal-container__links--link {
  margin: 2em auto;
}

.menu-modal {
  background-color: #fff !important;
}

.painting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.painting-container .title, .painting-container .link {
  margin: 1em auto;
}
.painting-container img {
  height: 150px;
  border: 0.5em solid white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
@media (min-width: 900px) {
  .painting-container img {
    height: 250px;
  }
}

.read-more-less {
  cursor: pointer;
}

.footer-navigation {
  padding: 2em 0;
  background-image: url("../images/ambiance/index/190.jpg");
  background-size: cover;
  position: relative;
}
.footer-navigation .overlay {
  background-color: #000;
  opacity: 0.3;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}
.footer-navigation .footer-navigation__content {
  position: relative;
  z-index: 1;
  color: #fff;
}
.footer-navigation .footer-navigation__content a {
  color: #fff;
}

.footer-navigation__logo h2 {
  font-family: Italianno;
  font-size: 2rem;
  text-align: center;
  margin: 0;
}

.footer-navigation__lvvp {
  padding: 1em;
}
.footer-navigation__lvvp picture {
  display: flex;
  justify-content: center;
}
.footer-navigation__lvvp picture img {
  width: 8em;
  height: 8em;
}

.devider {
  position: relative;
  display: flex;
  align-items: center;
}

.footer-navigation__links {
  list-style: none;
}
.hero-image {
  margin-top: 68px;
}

.image-container__image {
  width: 100%;
  max-height: 350px;
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  background-color: #000;
}
.image-container__image img {
  height: 100%;
  width: 100%;
  align-self: center;
  justify-self: center;
  opacity: 0.7;
}
.image-container__image .page-title {
  position: absolute;
  width: 100%;
  color: white;
}
.image-container__image .page-title__title {
  margin: 0;
}

footer {
  margin: 1em 0;
}

.therapist .therapist__image {
  max-width: 100%;
  max-height: 200px;
}

.actualisation-date span {
  display: inline-block;
}

.read-more-container {
  max-height: 200px;
  overflow: hidden;
  /* Set our transitions up. */
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  transition: max-height 1s;
}
.read-more-container.expanded {
  max-height: 10000px;
}

cite {
  display: block;
  margin-left: 1em;
}

.safe-mail {
  margin-left: 25px;
}

.modal-overlay, .modal-container {
  background: transparent !important;
}

/*# sourceMappingURL=static-app.css.map */